import React from "react"
import imgPortadaGuide from "@components/pagePlantillas/plantillas/plantilla3/image/imgPortada.png"
import img1 from "@components/pagePlantillas/plantillas/plantilla3/image/img.png"
import silder1 from "@components/pagePlantillas/plantillas/plantilla3/image/silder1.png"
import silder2 from "@components/pagePlantillas/plantillas/plantilla3/image/silder2.png"
import silder3 from "@components/pagePlantillas/plantillas/plantilla3/image/silder3.png"

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)


const data = {
  start: {
    support: "Plantilla | Ecommerce",
    title: <h1 className="titles-underlined-ebook ebook-ventas">
      10 ejemplos de encuestas de satisfacción en un ecommerce
    </h1>,
    description: <p className="text-h2-ebook guide__description">
      Las encuestas de satisfacción ayudan a conocer la experiencia de los clientes en un e-commerce. Define preguntas claras para medir el nivel de servicio y la facilidad de compra. Usa escalas numéricas para evaluar rapidez en la entrega y calidad del producto. Incluye preguntas abiertas para obtener detalles sobre áreas de mejora.
    </p>,
    classtext: "guide__description1",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Encuesta sobre la Experiencia General de Compra.",
      },
      {
        key: 2,
        text: "Encuesta sobre Navegación y Usabilidad del Sitio.",
      },
      {
        key: 3,
        text: "Encuesta sobre Calidad del Producto.",
      },
      {
        key: 4,
        text: "Encuesta sobre el Proceso de Checkout.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: silder1,
      },
      {
        key: 2,
        img: silder2,
      },
      {
        key: 3,
        img: silder3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: <p>
          Recuerda que, con esta plantilla, podrás acceder a una selección de preguntas enfocadas en cada etapa del proceso de compra. Asegúrate de utilizar formatos que faciliten respuestas precisas y útiles.
          <br /><br />
          Por último, descubre más detalles y accede a preguntas listas para usarlas Optimiza la experiencia de compra en tu e-commerce.
        </p>,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
